var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContextRequire } from '~/hooks';
import { cn } from '~/utils';
import { FormContext } from '../Form';
import FormRadioField from './FormRadioField';
function FormRadioGroup(_a) {
    var { name, label, options, className } = _a, props = __rest(_a, ["name", "label", "options", "className"]);
    // Get the form context
    const { form: { getFieldState }, } = useContextRequire(FormContext);
    const fieldState = getFieldState(name);
    return (_jsxs("fieldset", Object.assign({ className: cn('radio-group', className) }, props, { children: [_jsx("legend", { children: label }), _jsx("div", Object.assign({ className: "radio-group__options" }, { children: options.map((option, index) => (_jsx(FormRadioField, Object.assign({}, option), `radio-group-${name}-${index}`))) })), fieldState.error && (_jsx("span", Object.assign({ className: "radio-group__error" }, { children: fieldState.error.message })))] })));
}
export default FormRadioGroup;
