import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider, } from '@tanstack/react-query';
import { StrictMode, Suspense, } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { DefaultErrorComponent, ErrorBoundary, } from '~/components/ErrorBoundary';
import { Toaster } from '~/components/Toaster';
const defaultOptions = {
    useSuspense: true,
    useStrictMode: true,
    useQueryClientProvider: true,
    useErrorBoundary: true,
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
};
/**
 * A utility component that injects a React component into a specific DOM element.
 *
 * @param props - The props for the DOMInjector component.
 */
const DOMInjector = (props) => {
    var _a;
    const { reactComponent, DOMElement, path, Options } = props;
    const options = Object.assign(Object.assign({}, defaultOptions), Options);
    let Component = _jsx(_Fragment, {});
    if (path) {
        const router = createBrowserRouter([
            {
                path: `${path}`,
                element: reactComponent,
            },
        ]);
        Component = (_jsxs(_Fragment, { children: [_jsx(RouterProvider, { router: router }), _jsx(Toaster, { position: "top-right" })] }));
    }
    else {
        Component = (_jsxs(_Fragment, { children: [reactComponent, _jsx(Toaster, { position: "top-right" })] }));
    }
    if (options.useSuspense) {
        Component = (_jsx(Suspense, Object.assign({ fallback: (_a = options === null || options === void 0 ? void 0 : options.suspenseFallback) !== null && _a !== void 0 ? _a : null }, { children: Component })));
    }
    if (options.useQueryClientProvider) {
        /**
         * Initializes a QueryClient instance.
         */
        const queryClient = new QueryClient(options.queryClientConfig);
        Component = (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: Component })));
    }
    if (options.useErrorBoundary) {
        Component = (_jsx(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent, onReset: () => window.location.reload() }, { children: Component })));
    }
    else {
        Component = _jsx(ErrorBoundary, Object.assign({ fallback: null }, { children: Component }));
    }
    if (options.useStrictMode) {
        Component = _jsx(StrictMode, { children: Component });
    }
    /**
     * Retrieves the target HTML element by its ID and renders the provided React component into it.
     *
     * @returns Returns null if the target HTML element is not found; otherwise, returns the rendered React component.
     */
    const renderReactComponent = () => {
        const HtmlElement = document.getElementById(DOMElement.id);
        if (!HtmlElement) {
            return null;
        }
        const reactRoot = createRoot(HtmlElement, props.DOMElement.rootOptions);
        reactRoot.render(Component);
    };
    renderReactComponent();
};
export default DOMInjector;
