import { cva } from 'class-variance-authority';
export const buttonClasses = cva(['btn'], {
    variants: {
        variant: {
            primary: 'btn--primary',
            secondary: 'btn--secondary',
            ghost: 'btn--ghost',
            'ghost-link': 'btn--ghost-link',
            icon: 'btn--icon',
        },
    },
    defaultVariants: {
        variant: 'primary',
    },
});
const BUTTON_TYPE = ['button', 'submit', 'reset'];
