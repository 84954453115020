import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useState } from 'react';
import { getNotifications, markNotificationAsSeen, } from '~/actions/searchAlert.actions';
import { Button, Popover } from '~/components';
import { DefaultErrorComponent, ErrorBoundary, } from '~/components/ErrorBoundary';
import { BellIcon } from '~/icons';
import { buildQueryParams, cn } from '~/utils';
import { NotificationManagement } from '../NotificationManagement';
const NotificationCard = ({ notification, setSeen, }) => {
    // Handle the click event on the notification card
    const handleSubmit = () => {
        // If the notification is already seen, return
        if (notification.seen) {
            return;
        }
        setSeen();
    };
    return (_jsxs("div", Object.assign({ className: cn('notification-card', {
            'notification-card--unseen': !notification.seen,
        }) }, { children: [_jsxs("a", Object.assign({ 
                // Redirect to the search page with the search parameters from the notification
                href: `/?${buildQueryParams({ term: notification.term, industryId: notification.industry, countryId: notification.country })}`, 
                // Mark the notification as seen when the link is clicked -> skip if already seen
                onClick: handleSubmit, className: "notification-card__button" }, { children: [`You have ${notification.pageCount} new opportunities in ${notification.name}`, _jsx("span", Object.assign({ className: "notification-card__date" }, { children: moment(notification.createdDate).fromNow() }))] })), !notification.seen && (_jsx("button", Object.assign({ className: "notification-card__unseen-button", 
                // Mark the notification as seen when the button is clicked
                onClick: handleSubmit, onFocus: handleSubmit }, { children: _jsx("span", Object.assign({ className: "sr-only" }, { children: "Mark alert as read" })) })))] })));
};
const NotificationElement = () => {
    // Set the date locale to English
    moment.locale('en');
    // Get the query client to invalidate the notifications query
    const queryClient = useQueryClient();
    // State to handle the visibility of the notification management panel
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    // Get notifications from the API
    const { data: notifications } = useQuery({
        queryKey: ['notifications'],
        queryFn: getNotifications,
        placeholderData: [],
    });
    // Mutation to mark the notification as seen in the API
    const setSeenMutation = useMutation({
        mutationFn: markNotificationAsSeen,
        onSettled: () => {
            // Invalidate the notifications query to refetch the data
            queryClient.invalidateQueries('notifications');
        },
    });
    // Mark all notifications as seen
    const markAllAsRead = () => {
        // If there are no unseen notifications, return
        if (!notifications ||
            notifications.filter((notification) => !notification.seen).length === 0) {
            return;
        }
        // Update the notifications in the API
        setSeenMutation.mutate(notifications.map((n) => n.id));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Popover, { children: [_jsxs(Popover.Button, Object.assign({ color: "ghost", variant: "icon", className: cn('notification-button') }, { children: [_jsx(BellIcon, {}), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Notifications" })), notifications &&
                                notifications.filter((notification) => !notification.seen).length >
                                    0 && (_jsx("span", Object.assign({ className: "notification-badge" }, { children: _jsx("span", Object.assign({ className: "sr-only" }, { children: "You have new notifications" })) })))] })), _jsx(Popover.Panel, Object.assign({ className: "notification-panel" }, { children: _jsxs(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent }, { children: [_jsx("div", Object.assign({ className: "notification-panel__header" }, { children: _jsx("h3", Object.assign({ className: "heading--h4 heading--no-transform" }, { children: "Notifications" })) })), _jsx("div", Object.assign({ className: "notification-panel__content" }, { children: notifications && notifications.length > 0 ? (notifications.map((notification) => (_jsx(NotificationCard, { setSeen: () => setSeenMutation.mutate([notification.id]), notification: notification }, `notification-${notification.id}`)))) : (_jsx("div", Object.assign({ className: "notification-panel__empty" }, { children: _jsx("p", Object.assign({ className: "text--t3" }, { children: "You have no new notifications" })) }))) })), _jsxs("div", Object.assign({ className: "notification-panel__footer" }, { children: [_jsx(Button, Object.assign({ variant: "ghost-link", disabled: !notifications ||
                                                notifications.filter((notification) => !notification.seen)
                                                    .length === 0, onClick: markAllAsRead }, { children: "Mark all as read" })), _jsx(Button, Object.assign({ variant: "ghost-link", onClick: () => setNotificationsOpen(true) }, { children: "Manage search alerts" }))] }))] })) }))] }), notificationsOpen && (_jsx(NotificationManagement, { open: notificationsOpen, onClose: () => setNotificationsOpen(false) }))] }));
};
export default NotificationElement;
