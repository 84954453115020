var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { useContextRequire } from '~/hooks';
import { cn } from '~/utils';
import { FormContext } from '../Form';
function FormRadioField(_a) {
    var { label, name, className } = _a, props = __rest(_a, ["label", "name", "className"]);
    // Get a unique id for the input element
    const inputId = useId();
    // Get the form context
    const { form: { register }, } = useContextRequire(FormContext);
    return (_jsxs("div", Object.assign({ className: cn('radio-field', className) }, { children: [_jsx("input", Object.assign({ className: "radio-field__input", type: "radio", id: inputId }, register(name), props)), _jsx("label", Object.assign({ className: "radio-field__label", htmlFor: inputId }, { children: label }))] })));
}
export default FormRadioField;
