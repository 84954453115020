import { apiClient } from '~/utils/apiClient';
export const getSavedFilters = async () => {
    return await apiClient('/api/v1/moAlert');
};
export const postSaveSearch = async (data) => {
    return await apiClient('/api/v1/moAlert', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export const deleteSearchAlert = async (data) => {
    await apiClient(`/api/v1/moAlert/${data.id}`, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};
export const updateSearchAlert = async (data) => {
    return await apiClient('/api/v1/moAlert', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export const getNotifications = async () => {
    return await apiClient('/api/v1/moAlert/notifications');
};
export const markNotificationAsSeen = async (Ids) => {
    await apiClient(`/api/v1/moAlert/notificationsShown/${Ids.join(',')}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
