import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { isDefined, isEmpty } from '~/utils';
import { useSearchParams } from 'react-router-dom';
import { Listbox, SaveAsSearchAlert, } from '~/components';
import { ErrorBoundary, SubcategoryErrorComponent, } from '~/components/ErrorBoundary';
import Searchbar from '~/components/Searchbar/Searchbar';
import { getFilterData } from '~/actions/opportunity.actions';
import Subcategory from './components/Subcategory';
const SearchPage = ({ preloadCount = 10, sections, maxLoadCount = 10, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState((_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '');
    const [searchItem, setSearchItem] = useState({ id: undefined, name: (_b = searchParams.get('q')) !== null && _b !== void 0 ? _b : '' });
    const countrySearchParam = (_c = searchParams.get('country')) !== null && _c !== void 0 ? _c : '';
    const [country, setCountry] = useState(isEmpty(countrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(countrySearchParam),
        });
    const industrySearchParam = (_d = searchParams.get('industry')) !== null && _d !== void 0 ? _d : '';
    const [industry, setIndustry] = useState(isEmpty(industrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(industrySearchParam),
        });
    const { data: filtersData, isLoading: isFiltersLoading, isFetching: isFiltersFetching, } = useQuery({
        queryKey: ['filters', country.value, industry.value, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: () => getFilterData({
            contentTypeIds,
            searchItem: searchItem.name,
            country: country.value,
            industry: industry.value,
        }),
    });
    const contentTypeIds = sections && sections.map((sections) => sections.contentTypes).flat();
    const handleFilterChange = (fn, option, searchParamName) => {
        setSearchParams((prev) => {
            var _a, _b;
            const params = new URLSearchParams(prev);
            if (isDefined(option)) {
                params.set(searchParamName, (_b = (_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
            }
            else {
                params.delete(searchParamName);
            }
            return params;
        });
        // if the option is not defined, set the value to undefined
        fn(option !== null && option !== void 0 ? option : {
            label: '',
            value: undefined,
        });
    };
    const isFilterApplied = !isEmpty(country.value) ||
        !isEmpty(industry.value) ||
        !isEmpty(searchQuery);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "page-elements__actions container" }, { children: [_jsx("div", Object.assign({ className: "page-elements__actions__search" }, { children: _jsx(Searchbar, { searchQuery: [searchQuery, setSearchQuery], setSearchItem: setSearchItem, contentTypes: contentTypeIds, countrySearchParam: countrySearchParam, industrySearchParam: industrySearchParam }) })), _jsxs("div", Object.assign({ className: "page-elements__actions__filters" }, { children: [_jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Industry', value: undefined }, selected: industry, setSelected: (industry) => handleFilterChange(setIndustry, industry, 'industry'), isLoading: isFiltersLoading || isFiltersFetching, options: (_e = filtersData === null || filtersData === void 0 ? void 0 : filtersData.industries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _e !== void 0 ? _e : [] }), _jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Country', value: undefined }, selected: country, setSelected: (country) => handleFilterChange(setCountry, country, 'country'), isLoading: isFiltersLoading || isFiltersFetching, options: (_f = filtersData === null || filtersData === void 0 ? void 0 : filtersData.countries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _f !== void 0 ? _f : [] }), _jsxs("div", Object.assign({ className: "d-flex align-items-end gap-4" }, { children: [_jsx("a", Object.assign({ href: "/", className: "text--bold text--t2" }, { children: "Clear filters" })), _jsx(SaveAsSearchAlert, { isFilterApplied: isFilterApplied, country: country.value
                                            ? { id: country.value, name: country.label }
                                            : undefined, industry: industry.value
                                            ? { id: industry.value, name: industry.label }
                                            : undefined, contentTypes: contentTypeIds, term: searchQuery !== null && searchQuery !== void 0 ? searchQuery : '' })] }))] }))] })), _jsx("div", Object.assign({ className: "page-elements__results container" }, { children: sections.map((section) => {
                    var _a;
                    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(SubcategoryErrorComponent, { title: section.title }) }, { children: _jsx(Subcategory, { section: section, limit: preloadCount, searchQuery: (_a = searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) !== null && _a !== void 0 ? _a : '', country: country.value, industry: industry.value, maxLoadCount: maxLoadCount }) }), section.url));
                }) }))] }));
};
export default SearchPage;
