var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { cx } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { buttonClasses } from './Button.types';
const Button = forwardRef((_a, ref) => {
    var { children, type = 'button', disabled, isLoading, onClick, icon, iconPosition = 'left', variant } = _a, props = __rest(_a, ["children", "type", "disabled", "isLoading", "onClick", "icon", "iconPosition", "variant"]);
    /**
     * Click event handler for the button
     * This function is called when the button is clicked. It checks if the button is in a loading or disabled state and prevents the default click behavior if necessary. It then invokes the provided onClick handler.
     * @param {React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | MouseEvent>} e - The click event object
     * @returns {void}
     */
    const handleOnClick = (e) => {
        if (isLoading || disabled) {
            e.preventDefault();
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    const node = (_jsxs(_Fragment, { children: [icon && iconPosition === 'left' && icon, children, icon && iconPosition === 'right' && icon] }));
    return (_jsx("button", Object.assign({}, props, { type: type, disabled: disabled || isLoading, onClick: handleOnClick, ref: ref, className: buttonClasses({
            variant,
            className: cx(props.className, disabled && 'disabled', isLoading && 'btn--loading'),
        }) }, { children: node })));
});
Button.displayName = 'Button';
export default Button;
