import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, } from '@headlessui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchSearchSuggestions } from '~/actions/opportunity.actions';
import { useDebounce } from '~/hooks';
import { LoadingIcon } from '~/icons';
import { isEmpty } from '~/utils';
const Searchbar = ({ disabled = false, setSearchItem, contentTypes, searchQuery: [searchQuery, setSearchQuery], countrySearchParam, industrySearchParam, }) => {
    // set the search params to the query string params when the search query changes
    const [, setSearchParams] = useSearchParams();
    // used for debouncing the search query
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const searchButtonRef = useRef(null);
    /**
     * Uses the `useQuery` hook from React Query to fetch search suggestions.
     *
     * @returns {Object} An object containing:
     * - `options`: The search suggestions. This is the data returned from the query.
     * - `isLoading`: A boolean indicating if the query is currently loading.
     * - `isFetching`: A boolean indicating if the query is currently fetching.
     *
     * @property {Array} queryKey - An array that uniquely identifies this query. It includes the string 'search', the debounced search query, and the content types.
     * @property {Function} queryFn - An async function that fetches the data for this query. It sends a GET request to the `/api/v1/mo/search` endpoint with `contentTypes`, `debouncedSearchQuery`, and a fixed size of 10 as query parameters. It then parses the response as JSON.
     * @property {Boolean} placeholderData - This is set to `keepPreviousData`, which determines whether to keep the previous data while new data is being fetched.
     */
    const { data: options, isLoading, isFetching, isPending, isPlaceholderData, } = useQuery({
        queryKey: ['search', debouncedSearchQuery, contentTypes],
        queryFn: () => fetchSearchSuggestions(contentTypes, debouncedSearchQuery, countrySearchParam, industrySearchParam),
        placeholderData: keepPreviousData,
    });
    useEffect(() => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            if (isEmpty(searchQuery)) {
                params.delete('q');
            }
            else {
                params.set('q', searchQuery !== null && searchQuery !== void 0 ? searchQuery : '');
            }
            return params;
        });
        // Disabling because it wants the setSearchParams function to be a dependency and that could lead to endless rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);
    useEffect(() => {
        // used for removing tab index from combobox button that is automatically added by combobox.button component
        if (searchButtonRef &&
            searchButtonRef.current &&
            searchButtonRef.current.hasAttribute('tabindex')) {
            searchButtonRef.current.removeAttribute('tabindex');
        }
        return () => {
            if (searchButtonRef && searchButtonRef.current) {
                searchButtonRef.current = null;
            }
        };
    }, [searchButtonRef]);
    const _handleAPISearchQueryChange = (value) => {
        setDebouncedSearchQuery(value);
    };
    const handleAPISearchQueryChange = useDebounce(_handleAPISearchQueryChange, 300);
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setSearchItem({ id: undefined, name: searchQuery });
        }
    };
    return (_jsx("div", Object.assign({ className: `headlessui-autocomplete ${disabled || isLoading ? 'headlessui-autocomplete--disabled' : ''}` }, { children: _jsx(Combobox, Object.assign({ disabled: isLoading || disabled, onChange: (value) => value &&
                !isEmpty(value.linkUrl) &&
                window.location.assign(value.linkUrl) }, { children: _jsxs("div", Object.assign({ className: "headlessui-autocomplete-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "headlessui-autocomplete__actions" }, { children: [_jsxs("div", Object.assign({ className: "headlessui-autocomplete__input-wrapper" }, { children: [_jsx(ComboboxInput, { className: "headlessui-autocomplete__input-container__input", placeholder: "Search Market Opportunities", onChange: (value) => {
                                            setSearchQuery(value.target.value);
                                            handleAPISearchQueryChange(value.target.value);
                                        }, value: searchQuery, autoComplete: "off", onKeyUp: (e) => handleKeyUp(e) }), (isLoading || isPending || isFetching) && (_jsx("span", Object.assign({ className: "headlessui-listbox__button-autocomplete--icon" }, { children: _jsx(LoadingIcon, { className: "headlessui-autocomplete__input-container--icon--loading" }) })))] })), _jsx(ComboboxButton, Object.assign({ className: "headlessui-autocomplete__button", onClick: (e) => {
                                    e.preventDefault();
                                    setSearchItem({ id: undefined, name: searchQuery });
                                }, ref: searchButtonRef }, { children: "Search" }))] })), _jsxs(ComboboxOptions, Object.assign({ transition: true, className: "headlessui-autocomplete__options headless-transition" }, { children: [searchQuery && (_jsxs(ComboboxOption, Object.assign({ value: { id: null, name: searchQuery }, className: "headlessui-autocomplete__option--hidden" }, { children: ["Create \"", searchQuery, "!"] }))), options && options.length === 0 && searchQuery !== '' ? (_jsx("div", Object.assign({ className: "headlessui-autocomplete__option headlessui-autocomplete__option--not-found" }, { children: _jsx("span", { children: "Nothing found" }) }))) : (options &&
                                options.map((option) => (_jsx(ComboboxOption, Object.assign({ className: ({ focus }) => `headlessui-autocomplete__option ${focus ? 'headlessui-autocomplete__option--active' : ''}
                      ${isPlaceholderData
                                        ? 'headlessui-autocomplete__option--loading'
                                        : ''}
                      `, value: option }, { children: option.linkUrl ? (_jsx("a", Object.assign({ href: option.linkUrl }, { children: option.title }))) : (_jsx("span", { children: option.title })) }), `${option.id}-${option.title}`))))] }))] })) })) })));
};
export default Searchbar;
