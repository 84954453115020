var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAllCountries } from '~/actions/opportunity.actions';
import FormSelectField from './FormSelectField';
import { useContextRequire } from '~/hooks';
import { FormContext } from '../Form';
function FormSelectCountry(_a) {
    var _b;
    var props = __rest(_a, []);
    const { form } = useContextRequire(FormContext);
    const CountryOptions = [
        {
            value: '',
            label: 'Select a country',
        },
    ];
    const { data, isLoading } = useQuery({
        queryKey: ['all-countries'],
        queryFn: getAllCountries,
    });
    /**
     * Due to data being fetched after the form is rendered, we need to set the value of the field
     * after the data is fetched.
     */
    useEffect(() => {
        const currentValue = form.getValues(props.name);
        form.setValue(props.name, (currentValue || ''));
        return () => {
            form.resetField(props.name);
        };
    }, [data, form, props.name]);
    return (_jsx(FormSelectField, Object.assign({ disabled: !data, isLoading: isLoading, options: [
            ...CountryOptions,
            ...((_b = data === null || data === void 0 ? void 0 : data.map((item) => ({ value: item.id, label: item.name }))) !== null && _b !== void 0 ? _b : []),
        ] }, props)));
}
export default FormSelectCountry;
