export const ALERT_FREQUENCY = [
    {
        alertFrequencyId: 0,
        alertFrequencyName: 'None',
        disabled: true,
    },
    {
        alertFrequencyId: 1,
        alertFrequencyName: 'Daily',
        disabled: false,
    },
    {
        alertFrequencyId: 2,
        alertFrequencyName: 'Weekly',
        disabled: false,
    },
    {
        alertFrequencyId: 3,
        alertFrequencyName: 'Every two weeks',
        disabled: false,
    },
    {
        alertFrequencyId: 4,
        alertFrequencyName: 'Monthly',
        disabled: false,
    },
];
export const getAlertFrequencyById = (alertFrequencyId) => {
    return ALERT_FREQUENCY.find((alert) => alert.alertFrequencyId === alertFrequencyId);
};
