var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseButton, Dialog as DialogUI, DialogBackdrop, DialogPanel, } from '@headlessui/react';
import { XMark } from '~/icons';
import { cn } from '~/utils';
import { Button } from '../Button';
// The children props actually exist in the DialogProps type, but TypeScript doesn't know that
const Dialog = (_a) => {
    var { 
    // eslint-disable-next-line
    children, 
    // eslint-disable-next-line
    className, 
    // eslint-disable-next-line
    as = 'div', 
    // eslint-disable-next-line
    width = 'md' } = _a, props = __rest(_a, ["children", "className", "as", "width"]);
    return (_jsxs(DialogUI, Object.assign({ as: as, className: cn('headlessui-dialog', className) }, props, { children: [_jsx(DialogBackdrop, { className: "headlessui-dialog__backdrop" }), _jsx("div", Object.assign({ className: "headlessui-dialog__container" }, { children: _jsx(DialogPanel, Object.assign({ className: `headlessui-dialog__panel headlessui-dialog--${width}` }, { children: children })) }))] })));
};
export const DialogHeader = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (_jsxs("div", Object.assign({ className: cn('headlessui-dialog__header', className) }, props, { children: [children, _jsx(CloseButton, Object.assign({ as: Button, variant: "icon" }, { children: _jsx(XMark, {}) }))] })));
};
export const DialogBody = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: cn('headlessui-dialog__body', className) }, props, { children: children })));
};
export const DialogFooter = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: cn('headlessui-dialog__footer', className) }, props, { children: children })));
};
Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
export default Dialog;
