import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import z from 'zod';
import { postSaveSearch, updateSearchAlert, } from '~/actions/searchAlert.actions';
import { ALERT_FREQUENCY } from '~/constants';
import { isDefined, isEmpty, pickBy } from '~/utils';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Form } from '../Form';
import FormField from '../Form/FormField/FormField';
import FormRadioGroup from '../Form/FormRadioField/FormRadioGroup';
import FormSelectField from '../Form/FormSelectField/FormSelectField';
const FormSchema = z.object({
    id: z.number().optional(),
    name: z.string(),
    email: z.union([z.literal(''), z.string().email()]).optional(),
    alertFrequency: z.string(),
    query: z.object({
        term: z.string().optional(),
        contentTypes: z.array(z.number()).default([]),
        industry: z.string().optional(),
        country: z.string().optional(),
    }),
});
const EditSearchAlertDialog = ({ initialData, open, onClose, onSuccess, onError, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const queryClient = useQueryClient();
    const formData = Object.assign(Object.assign({}, initialData), { name: (_a = initialData.name) !== null && _a !== void 0 ? _a : '', alertFrequency: (_c = (_b = initialData.alertFrequency) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ALERT_FREQUENCY[1].alertFrequencyId.toString(), query: Object.assign(Object.assign({}, initialData.query), { contentTypes: (_f = (_e = (_d = initialData.query) === null || _d === void 0 ? void 0 : _d.contentTypes) === null || _e === void 0 ? void 0 : _e.map(Number)) !== null && _f !== void 0 ? _f : [], industry: (_j = (_h = (_g = initialData.query) === null || _g === void 0 ? void 0 : _g.industry) === null || _h === void 0 ? void 0 : _h.id.toString()) !== null && _j !== void 0 ? _j : '', country: (_m = (_l = (_k = initialData.query) === null || _k === void 0 ? void 0 : _k.country) === null || _l === void 0 ? void 0 : _l.id.toString()) !== null && _m !== void 0 ? _m : '' }) });
    const form = useForm({
        defaultValues: Object.assign({ id: undefined, name: '', email: '', alertFrequency: ALERT_FREQUENCY[1].alertFrequencyId.toString(), query: {
                term: '',
                contentTypes: (_p = (_o = initialData.query) === null || _o === void 0 ? void 0 : _o.contentTypes.map(Number)) !== null && _p !== void 0 ? _p : [],
                industry: undefined,
                country: undefined,
            } }, pickBy(formData, (v) => isDefined(v))),
        resolver: zodResolver(FormSchema),
    });
    const updateSearchAlertMutation = useMutation({
        mutationKey: ['updateSearchAlert'],
        mutationFn: updateSearchAlert,
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
                return;
            }
            queryClient.invalidateQueries({ queryKey: ['savedFilters'] });
            toast.success('Search alert updated successfully');
            onClose();
        },
        onError: (error) => {
            if (onError) {
                onError(error);
                return;
            }
            toast.error('Could not update search alert', {
                description: error.message,
            });
        },
    });
    const createSearchAlertMutation = useMutation({
        mutationFn: postSaveSearch,
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
                return;
            }
            queryClient.invalidateQueries({ queryKey: ['savedFilters'] });
            toast.success('Search alert created successfully');
            onClose();
        },
        onError: (error) => {
            if (onError) {
                onError(error);
                return;
            }
            toast.error('Could not update search alert', {
                description: error.message,
            });
        },
    });
    const onSubmit = async (data) => {
        var _a, _b, _c, _d;
        if (data.id && data.id > 0) {
            const transformedData = Object.assign(Object.assign({}, data), { email: (_a = initialData.email) !== null && _a !== void 0 ? _a : undefined, alertFrequency: parseInt(data.alertFrequency), query: Object.assign(Object.assign({}, data.query), { term: (_b = data.query.term) !== null && _b !== void 0 ? _b : '', industry: isEmpty(data.query.industry)
                        ? undefined
                        : parseInt(data.query.industry), country: isEmpty(data.query.country)
                        ? undefined
                        : parseInt(data.query.country) }) });
            await updateSearchAlertMutation.mutateAsync(transformedData);
        }
        else {
            await createSearchAlertMutation.mutateAsync({
                name: data.name,
                email: (_c = initialData.email) !== null && _c !== void 0 ? _c : undefined,
                alertFrequency: parseInt(data.alertFrequency),
                query: {
                    term: (_d = data.query.term) !== null && _d !== void 0 ? _d : '',
                    contentTypes: data.query.contentTypes,
                    industry: isEmpty(data.query.industry)
                        ? undefined
                        : parseInt(data.query.industry),
                    country: isEmpty(data.query.country)
                        ? undefined
                        : parseInt(data.query.country),
                },
            });
        }
    };
    return (_jsxs(Dialog, Object.assign({ width: "sm", open: open, onClose: onClose }, { children: [_jsx(Dialog.Header, { children: _jsxs("h2", { children: [formData.id ? 'Edit search alert' : 'Create search alert', " "] }) }), _jsxs(Form, Object.assign({ form: form, onSubmit: onSubmit }, { children: [_jsxs(Dialog.Body, Object.assign({ className: "p-4 d-flex flex-column gap-4" }, { children: [_jsx(FormField, { label: "Alert Name", required: true, placeholder: "Alert Name", name: "name" }), _jsx(FormField, { placeholder: "Search Term", label: "Search Term", name: "query.term" }), _jsx(FormSelectField.Industry, { name: "query.industry", label: "Industry" }), _jsx(FormSelectField.Country, { name: "query.country", label: "Country" }), _jsx(FormRadioGroup, { label: "Alert Frequency", name: "alertFrequency", options: ALERT_FREQUENCY.filter((alert) => !alert.disabled).map((alert) => ({
                                    value: alert.alertFrequencyId,
                                    label: alert.alertFrequencyName,
                                    name: 'alertFrequency',
                                })) }), formData.id && (_jsx(FormField, { label: "Email", placeholder: "Email", name: "email", disabled: true }))] })), _jsxs(Dialog.Footer, Object.assign({ className: "gap-4" }, { children: [_jsx(Button, Object.assign({ className: "w-100", variant: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ disabled: form.formState.isSubmitting ||
                                    form.formState.disabled ||
                                    form.formState.isValidating ||
                                    !form.formState.isValid, className: "w-100", type: "submit" }, { children: "Save" }))] }))] }))] })));
};
export default EditSearchAlertDialog;
