import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox as ListboxUI, ListboxButton, ListboxOption, ListboxOptions, } from '@headlessui/react';
import { ChevronIcon, LoadingIcon } from '~/icons';
import { isEmpty } from '~/utils';
import ListboxAutocomplete from './ListboxAutocomplete';
function Listbox({ options, selected, setSelected, disabled, isLoading, emptyOption, }) {
    return (_jsx("div", Object.assign({ className: "headlessui-listbox" }, { children: _jsx(ListboxUI, Object.assign({ value: selected, onChange: setSelected, disabled: disabled || isLoading }, { children: _jsxs("div", Object.assign({ className: "headlessui-listbox-wrapper" }, { children: [_jsxs(ListboxButton, Object.assign({ className: "headlessui-listbox__button" }, { children: [_jsx("span", { children: selected.label }), isLoading ? _jsx(LoadingIcon, {}) : _jsx(ChevronIcon, {})] })), _jsxs(ListboxOptions, Object.assign({ transition: true, className: "headlessui-listbox__options headless-transition" }, { children: [emptyOption && !isEmpty(selected.value) && (_jsxs(ListboxOption, Object.assign({ className: "headlessui-listbox__option headlessui-listbox__option--clear-selection", value: { value: emptyOption.value, label: emptyOption.label } }, { children: [_jsx("span", { children: "Remove selection" }), _jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: "1.5", stroke: "currentColor" }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M6 18L18 6M6 6l12 12" }) }))] }))), options.map((option, key) => (_jsx(ListboxOption, Object.assign({ value: option, disabled: option.disabled, className: ({ selected, disabled }) => `headlessui-listbox__option ${selected ? 'headlessui-listbox__option--selected' : ''} ${disabled ? 'headlessui-listbox__option--disabled' : ''}` }, { children: _jsx("span", { children: option.label }) }), key)))] }))] })) })) })));
}
Listbox.Autocomplete = ListboxAutocomplete;
export default Listbox;
