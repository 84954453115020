var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { useContextRequire } from '~/hooks';
import { cn } from '~/utils';
import { FormContext } from '../Form';
function FormField(_a) {
    var { name, label, type = 'text' } = _a, props = __rest(_a, ["name", "label", "type"]);
    // Get a unique id for the input element
    const inputId = useId();
    // Get the form context
    const { form: { register, getFieldState }, } = useContextRequire(FormContext);
    const fieldState = getFieldState(name);
    return (_jsxs("div", Object.assign({ className: "text-field" }, { children: [_jsxs("div", Object.assign({ className: cn('text-field__wrapper') }, { children: [_jsx("label", Object.assign({ className: "text-field__label", htmlFor: inputId }, { children: label })), _jsx("input", Object.assign({ className: "text-field__input", type: type, id: inputId, "aria-required": props.required }, register(name), props)), props.required && _jsx("span", Object.assign({ className: "text-field__required" }, { children: "*" }))] })), fieldState.error && (_jsx("span", Object.assign({ className: "text-field__error" }, { children: fieldState.error.message })))] })));
}
FormField.displayName = 'FormField';
export default FormField;
