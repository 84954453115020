import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState, } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFilterData } from '~/actions/opportunity.actions';
import { Listbox, OpportunityCard, Pagination, SaveAsSearchAlert, Searchbar, } from '~/components';
import { arrayOf, buildAPIUrl, isDefined, isEmpty } from '~/utils';
import { apiClient } from '~/utils/apiClient';
const SubcategoryPage = ({ preloadCount = 9, pageName, contentTypes, landingPageUrl, iconText, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState((_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '');
    const countrySearchParam = (_b = searchParams.get('country')) !== null && _b !== void 0 ? _b : '';
    const [country, setCountry] = useState(isEmpty(countrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(countrySearchParam),
        });
    const industrySearchParam = (_c = searchParams.get('industry')) !== null && _c !== void 0 ? _c : '';
    const [industry, setIndustry] = useState(isEmpty(industrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(industrySearchParam),
        });
    const [searchItem, setSearchItem] = useState({ id: undefined, name: (_d = searchParams.get('q')) !== null && _d !== void 0 ? _d : '' });
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
    const { data: filtersData, isLoading: isFiltersLoading, isFetching: isFiltersFetching, } = useQuery({
        queryKey: ['filters', contentTypes, country, industry, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: () => getFilterData({
            contentTypeIds: contentTypes,
            searchItem: searchItem === null || searchItem === void 0 ? void 0 : searchItem.name,
            country: country.value,
            industry: industry.value,
        }),
    });
    const { data, isLoading, isPlaceholderData } = useQuery({
        queryKey: [contentTypes, page, country, industry, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: async () => await apiClient(buildAPIUrl('/api/v1/mo', {
            contentTypes: contentTypes,
            page: page,
            country: country.value,
            industry: industry.value,
            q: searchItem === null || searchItem === void 0 ? void 0 : searchItem.name,
            size: preloadCount,
        })),
        placeholderData: keepPreviousData,
    });
    const onPageChange = (page) => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('page', page.toString());
            return params;
        });
        setPage(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const handleFilterChange = (fn, option, searchParamName) => {
        setSearchParams((prev) => {
            var _a, _b;
            const params = new URLSearchParams(prev);
            if (isDefined(option)) {
                params.set(searchParamName, (_b = (_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
            }
            else {
                params.delete(searchParamName);
            }
            setPage(1);
            params.set('page', '1');
            return params;
        });
        fn(option !== null && option !== void 0 ? option : {
            label: '',
            value: undefined,
        });
    };
    const isFilterApplied = !isEmpty(country.value) ||
        !isEmpty(industry.value) ||
        !isEmpty(searchQuery);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "page-elements__actions container" }, { children: [_jsx("div", Object.assign({ className: "page-elements__actions__search" }, { children: _jsx(Searchbar, { searchQuery: [searchQuery, setSearchQuery], setSearchItem: setSearchItem, contentTypes: contentTypes, countrySearchParam: countrySearchParam, industrySearchParam: industrySearchParam }) })), _jsxs("div", Object.assign({ className: "page-elements__actions__filters" }, { children: [_jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Industry', value: undefined }, selected: industry, setSelected: (industry) => handleFilterChange(setIndustry, industry, 'industry'), isLoading: isFiltersLoading || isFiltersFetching, options: (_e = filtersData === null || filtersData === void 0 ? void 0 : filtersData.industries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total == 0,
                                }))) !== null && _e !== void 0 ? _e : [] }), _jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Country', value: undefined }, selected: country, setSelected: (country) => handleFilterChange(setCountry, country, 'country'), isLoading: isFiltersLoading || isFiltersFetching, options: (_f = filtersData === null || filtersData === void 0 ? void 0 : filtersData.countries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _f !== void 0 ? _f : [] }), _jsxs("div", Object.assign({ className: "d-flex gap-4 align-items-end" }, { children: [_jsx("a", Object.assign({ href: "/", className: "text--bold text--t2" }, { children: "Clear filters" })), _jsx(SaveAsSearchAlert, { isFilterApplied: isFilterApplied, country: country.value
                                            ? { id: country.value, name: country.label }
                                            : undefined, industry: industry.value
                                            ? { id: industry.value, name: industry.label }
                                            : undefined, contentTypes: contentTypes, term: searchQuery !== null && searchQuery !== void 0 ? searchQuery : '' })] }))] }))] })), _jsxs("div", Object.assign({ className: "page-elements__results container category" }, { children: [_jsxs("div", Object.assign({ className: "category__heading" }, { children: [_jsx("h2", Object.assign({ className: "category__heading__title" }, { children: `${pageName} (${!data || isLoading || isPlaceholderData
                                    ? 'Loading...'
                                    : (_g = data === null || data === void 0 ? void 0 : data.totalCount) !== null && _g !== void 0 ? _g : ''}${isEmpty(searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) &&
                                    isEmpty(country.value) &&
                                    isEmpty(industry.value)
                                    ? ''
                                    : ' Found'})` })), _jsx("a", Object.assign({ href: landingPageUrl, className: "category__heading__back-button" }, { children: "Back to frontpage \u00BB" }))] })), isLoading || !data || !data.hits ? (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "category__results" }, { children: arrayOf(preloadCount).map((_, i) => (_jsx(OpportunityCard.Skeleton, {}, i))) })) })) : (data.hits.length !== 0 && (_jsx("div", Object.assign({ className: "category__results" }, { children: data.hits.map((item, key) => (_jsx(OpportunityCard, { opportunity: item, iconText: iconText, isPlaceholder: isPlaceholderData }, `Page${page}/Opportunity${key}`))) })))), !isLoading && data && ((_h = data.hits) === null || _h === void 0 ? void 0 : _h.length) === 0 && data.latest && (_jsxs("div", Object.assign({ className: "category__no-results" }, { children: [_jsx("h3", Object.assign({ className: "heading--no-transform" }, { children: "We didn't find match for your search." })), _jsx("h3", Object.assign({ className: "heading--no-transform" }, { children: "Here are some of our latest in Act Now category." })), _jsx("div", Object.assign({ className: "category__results" }, { children: data.latest.map((item, key) => (_jsx(OpportunityCard, { opportunity: item, iconText: iconText }, `Page${page}/LatestOpportunity${key}`))) }))] })))] })), _jsx("div", Object.assign({ className: "container page-elements__pagination" }, { children: _jsx(Pagination, { currentPage: page, onPageChange: onPageChange, totalPages: Math.ceil(((data === null || data === void 0 ? void 0 : data.totalCount) || 1) / preloadCount), disabled: isLoading || isPlaceholderData }) }))] }));
};
export default SubcategoryPage;
