var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEmpty } from '~/utils';
import OpportunityNotFound from './OpportunityNotFound';
import OpportunitySkeleton from './OpportunitySkeleton';
import OpportunityShowMore from './OpportunityShowMore';
const CountryFlag = ({ country, isGlobal, }) => {
    switch (true) {
        // Check if there is country "All Countries" based on "isGlobal" boolean => display "All Countries"
        case isGlobal:
            return (_jsx("div", Object.assign({ className: "opportunity-card__country" }, { children: _jsx("span", Object.assign({ className: "opportunity-card__country__name" }, { children: "All Countries" })) })));
        // Check if there is more than one country => display multiple countries
        case country.length > 1:
            return (_jsx("div", Object.assign({ className: "opportunity-card__country" }, { children: _jsx("span", Object.assign({ className: "opportunity-card__country__name" }, { children: "Multiple countries" })) })));
        case country.length === 1:
            return (_jsxs("div", Object.assign({ className: "opportunity-card__country" }, { children: [_jsx("img", { src: country[0].flagUrl, alt: country[0].name + ' flag', className: "opportunity-card__country__flag", width: 48, height: 32 }), _jsx("span", Object.assign({ className: "opportunity-card__country__name" }, { children: country[0].name }))] })));
        default:
            return null;
    }
};
function OpportunityCard(_a) {
    var { opportunity, iconText, isPlaceholder = false } = _a, props = __rest(_a, ["opportunity", "iconText", "isPlaceholder"]);
    return (_jsxs("a", Object.assign({}, props, { href: opportunity.linkUrl, className: `opportunity-card ${isPlaceholder ? 'opportunity-card--placeholder' : ''}` }, { children: [_jsxs("div", Object.assign({ className: "opportunity-card__heading-container" }, { children: [_jsx("img", { src: opportunity.imageUrl + '?width=200&height=150', alt: opportunity.title, loading: "lazy" }), _jsxs("div", { children: [_jsx("span", Object.assign({ className: "opportunity-card__heading-container__opportunity-type text--bold" }, { children: opportunity.type })), !isEmpty(iconText) && (_jsx("span", Object.assign({ className: "opportunity-card__heading-container__act-now text--bold" }, { children: iconText })))] })] })), _jsx(CountryFlag, { country: opportunity.countries, isGlobal: opportunity.isGlobal }), _jsx("h4", Object.assign({ className: "opportunity-card__title" }, { children: opportunity.title })), _jsxs("p", Object.assign({ className: "opportunity-card__industry" }, { children: ["Industry ", _jsx("span", Object.assign({ className: "text--bold" }, { children: opportunity.industry }))] }))] })));
}
OpportunityCard.Skeleton = OpportunitySkeleton;
OpportunityCard.NotFound = OpportunityNotFound;
OpportunityCard.ShowMore = OpportunityShowMore;
/**
 * @component OpportunityCard
 * @description A card that displays an opportunity
 * @default
 */
export default OpportunityCard;
