var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { useContextRequire } from '~/hooks';
import { ChevronIcon, LoadingIcon } from '~/icons';
import { cn } from '~/utils';
import { FormContext } from '../Form';
import FormSelectCountry from './FormSelectCountry';
import FormSelectIndustry from './FormSelectIndustry';
function FormSelectField(_a) {
    var { name, label, options, isLoading, disabled } = _a, props = __rest(_a, ["name", "label", "options", "isLoading", "disabled"]);
    // Get a unique id for the input element
    const inputId = useId();
    // Get the form context
    const { form: { register, getFieldState }, } = useContextRequire(FormContext);
    const fieldState = getFieldState(name);
    return (_jsxs("div", Object.assign({ className: "text-field" }, { children: [_jsxs("div", Object.assign({ className: cn('text-field__wrapper', isLoading && 'text-field__wrapper--loading') }, { children: [_jsx("label", Object.assign({ className: "text-field__label", htmlFor: inputId }, { children: label })), _jsx("select", Object.assign({ className: 'text-field__input', id: inputId, disabled: disabled || isLoading, "aria-required": props.required }, register(name), props, { children: options.map((option, index) => (_jsx("option", Object.assign({ value: option.value }, { children: option.label }), `id-${name}-${index}`))) })), props.required && _jsx("span", Object.assign({ className: "text-field__required" }, { children: "*" })), _jsx("span", Object.assign({ className: "text-field__select-icon" }, { children: isLoading ? _jsx(LoadingIcon, {}) : _jsx(ChevronIcon, {}) }))] })), fieldState.error && (_jsx("span", Object.assign({ className: "text-field__error" }, { children: fieldState.error.message })))] })));
}
FormSelectField.displayName = 'FormSelectField';
FormSelectField.Country = FormSelectCountry;
FormSelectField.Industry = FormSelectIndustry;
export default FormSelectField;
