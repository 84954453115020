import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense, useState } from 'react';
import { getSavedFilters } from '~/actions/searchAlert.actions';
import { Button, Dialog } from '~/components';
import { DefaultErrorComponent, ErrorBoundary, } from '~/components/ErrorBoundary';
import { SearchAlertCard } from '~/components/SearchAlertCard';
import EditSearchAlertDialog from '~/components/SearchAlertCard/EditSearchAlertDialog';
import { isDefined } from '~/utils';
const SuspenseArray = () => {
    const array = Array.from({ length: 4 });
    return (_jsx("div", { children: array.map((_, index) => (_jsx(SearchAlertCard.Skeleton, {}, index))) }));
};
const SearchBody = () => {
    const { data } = useSuspenseQuery({
        queryKey: ['savedFilters'],
        queryFn: getSavedFilters,
    });
    return (_jsx("div", Object.assign({ className: "d-flex flex-column" }, { children: data && data.length === 0 ? (_jsx("div", Object.assign({ className: "p-4" }, { children: _jsx("p", { children: "You have no active alerts" }) }))) : (data.map((alert) => (_jsx(SearchAlertCard, { searchAlert: alert }, alert.id)))) })));
};
const NotificationManagement = ({ onClose, open }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, Object.assign({ width: "sm", open: open, onClose: onClose }, { children: [_jsx(Dialog.Header, { children: _jsx("h2", { children: "Manage search alerts" }) }), _jsx(Dialog.Body, { children: _jsx(ErrorBoundary, Object.assign({ FallbackComponent: DefaultErrorComponent }, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(SuspenseArray, {}) }, { children: _jsx(SearchBody, {}) })) })) }), _jsx(Dialog.Footer, { children: _jsx(Button, Object.assign({ variant: "secondary", onClick: () => setEditDialogOpen({}) }, { children: "Create new alert" })) })] })), isDefined(editDialogOpen) && (_jsx(EditSearchAlertDialog, { open: isDefined(editDialogOpen), onClose: () => setEditDialogOpen(null), initialData: editDialogOpen }))] }));
};
export default NotificationManagement;
