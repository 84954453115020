import { buildAPIUrl } from '~/utils';
import { apiClient } from '~/utils/apiClient';
export const getFilterData = async ({ contentTypeIds, country, industry, searchItem, }) => {
    return await apiClient(buildAPIUrl('/api/v1/mo/filters', {
        contentTypes: contentTypeIds,
        q: searchItem,
        country: country,
        industry: industry,
    }));
};
export const getSubcategoryData = async ({ contentTypes, maxCount, size, country, industry, page, searchQuery, }) => {
    return await apiClient(buildAPIUrl('/api/v1/mo', {
        contentTypes: contentTypes,
        page: page !== null && page !== void 0 ? page : 1,
        country: country,
        industry: industry,
        q: searchQuery !== null && searchQuery !== void 0 ? searchQuery : '',
        size: size,
        maxCount: maxCount,
    }));
};
export const getAllCountries = async () => {
    return await apiClient('/api/v1/mo/allCountries');
};
export const getAllIndustries = async () => {
    return await apiClient('/api/v1/mo/allIndustries');
};
/**
 * Fetches search suggestions based on the provided content types and search query.
 * @param {number[] | string[]} contentTypes - An array of content type IDs to filter the search results.
 * @param {string} debouncedSearchQuery - The search query to fetch suggestions for.
 * @returns {Promise<any>} - A promise that resolves to the search suggestions data.
 * @throws {Error} - If an error occurs while fetching data.
 */
export const fetchSearchSuggestions = async (contentTypes, debouncedSearchQuery, countrySearchParam, industrySearchParam) => {
    return await apiClient(buildAPIUrl('/api/v1/mo/search', {
        contentTypes: contentTypes,
        q: debouncedSearchQuery,
        size: 10,
        country: countrySearchParam,
        industry: industrySearchParam,
    }));
};
