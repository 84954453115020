import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { toast } from 'sonner';
import { ALERT_FREQUENCY } from '~/constants';
import { BellIcon, XMark } from '~/icons';
import { NotificationManagement } from '~/MarketOpportunities/pages/NotificationManagement';
import { isDefined, isEmpty } from '~/utils';
import { Button } from '../Button';
import EditSearchAlertDialog from '../SearchAlertCard/EditSearchAlertDialog';
const SEARCH_ALERT_TOAST_ID = 'searchAlertId';
const getSearchAlertName = (industry, country) => {
    if (isEmpty(industry) && isEmpty(country)) {
        return 'New search alert';
    }
    if (isEmpty(industry)) {
        return `Alert for ${country.name}`;
    }
    if (isEmpty(country)) {
        return `Alert for ${industry.name}`;
    }
    return `${industry.name} in ${country.name}`;
};
const SaveAsSearchAlert = ({ isFilterApplied, industry, country, contentTypes, term, }) => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [createDialogOpen, setEditDialogOpen] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "ghost-link", disabled: !isFilterApplied, onClick: () => setEditDialogOpen({
                    name: getSearchAlertName(industry, country),
                    query: {
                        term,
                        contentTypes,
                        country,
                        industry,
                    },
                    alertFrequency: ALERT_FREQUENCY[2].alertFrequencyId,
                }) }, { children: "Save as search alert" })), createDialogOpen && (_jsx(EditSearchAlertDialog, { open: isDefined(createDialogOpen), onClose: () => setEditDialogOpen(null), initialData: createDialogOpen, onSuccess: () => {
                    toast('Your search alert has been created', {
                        icon: _jsx(BellIcon, { className: "sa-toast__icon" }),
                        description: (_jsx(Button, Object.assign({ variant: "ghost-link", onClick: () => {
                                setNotificationsOpen(true);
                                toast.dismiss(SEARCH_ALERT_TOAST_ID);
                            }, className: "sa-toast__manage-button" }, { children: "Manage search alerts" }))),
                        id: SEARCH_ALERT_TOAST_ID,
                        className: 'sa-toast',
                        action: (_jsx(Button, Object.assign({ variant: "icon", onClick: () => toast.dismiss(SEARCH_ALERT_TOAST_ID) }, { children: _jsx(XMark, {}) }))),
                    });
                    setEditDialogOpen(null);
                }, onError: (error) => toast.error('Could not save search', {
                    description: error.message,
                }) })), notificationsOpen && (_jsx(NotificationManagement, { open: notificationsOpen, onClose: () => setNotificationsOpen(false) }))] }));
};
export default SaveAsSearchAlert;
